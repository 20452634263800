import { useNuxtApp } from "#imports";
import useNotify from "~/composables/useNotify";
import { createMutation } from "~/utils/queryUtils";
import type { InternalApi } from "nitropack";
import type { SendTestEmailPayload } from "~/server/api/cm/emails.post";

import type { Email } from "../models/Email/Email.model";
import type { EmailTemplate } from "../models/Email/EmailTemplate.model";
import FindToken from "~/utils/tokenPathFinder";
import type { Dictionary } from "~/utils/typeHelpers";
import type { ScreenResultViewModel } from "../models/Case/Screen.model";
import type { BackendBookingViewModel } from "../models/Case/Booking.viewmodel";

const cleanRecipients = (recipients: string) =>
  recipients.split(",").map((email) => {
    return { address: email.trim() };
  });

const mapAdditionalTemplateRequirements = (
  template: EmailTemplate,
  screenResult: ScreenResultViewModel | undefined,
  booking: BackendBookingViewModel | null | undefined
) => {
  if (!booking) return;
  const additionalParameters: Dictionary<Dictionary<string>> = {};
  if (template.includeHits && screenResult && screenResult?.hits) {
    const key = "Hits";
    const matchFields = screenResult.hits.flatMap((hit) =>
      hit.matches.map((match) => match.field)
    );
    additionalParameters[key] = extractFromBooking(matchFields, booking);
  }

  if (template.includedBookingProperties.length) {
    const key = "Details";
    additionalParameters[key] = extractFromBooking(
      template.includedBookingProperties,
      booking,
      true
    );
  }
  return additionalParameters;
};

const extractFromBooking = (
  paths: string[],
  booking: BackendBookingViewModel,
  replacePeriods = false
) => {
  const mappedMatches: Dictionary<string> = {};

  paths.map((field) => {
    const matchText = FindToken(field, booking);
    if (matchText) {
      let key = field;
      if (replacePeriods) key = field.replace(/\./g, "-");
      mappedMatches[key] = matchText as string;
    }
  });
  return mappedMatches;
};

export const getPayload = ({
  recipients,
  emailTemplate,
  message,
  screenResult,
  booking,
}: {
  recipients: string;
  emailTemplate: EmailTemplate;
  message?: string;
  screenResult?: ScreenResultViewModel;
  booking?: BackendBookingViewModel;
}) => {
  return {
    templateId: emailTemplate.id,
    recipients: cleanRecipients(recipients),
    message: message,
    additionalBookingProperties: mapAdditionalTemplateRequirements(
      emailTemplate,
      screenResult,
      booking
    ),
  };
};

export const useEmailService = () => {
  const { $api } = useNuxtApp();
  const { notifyError, notifySuccess } = useNotify();

  // Send Emails
  const sendCaseEmail = async (
    caseId: string,
    payload: Email,
    screenResult?: ScreenResultViewModel,
    booking?: BackendBookingViewModel
  ) =>
    await $api<InternalApi[`/api/cm/case/:id/emails`]["post"]>(
      `/api/cm/case/${caseId}/emails`,
      {
        method: "POST",
        body: getPayload({
          recipients: payload.recipients,
          emailTemplate: payload.emailTemplate,
          message: payload.message,
          screenResult: screenResult,
          booking: booking,
        }),
      }
    );

  const useSendCaseEmailMutation = () =>
    createMutation(
      ({
        caseId,
        payload,
        screenResult,
        booking,
      }: {
        caseId: string;
        payload: Email;
        screenResult?: ScreenResultViewModel;
        booking?: BackendBookingViewModel;
      }) => sendCaseEmail(caseId, payload, screenResult, booking),
      {
        onError: () => notifyError("Error sending email"),
        onSuccess: () => notifySuccess("Success", "Email sent"),
      }
    );

  // This is SEND on the [id] modal
  const sendTestEmail = async (payload: SendTestEmailPayload) =>
    await $api(`/api/cm/emails`, {
      method: "POST",
      body: payload,
    });

  const useSendTestEmailMutation = () =>
    createMutation((payload: SendTestEmailPayload) => sendTestEmail(payload), {
      onSuccess: () => notifySuccess("Success", "Email sent"),
      onError: () => notifyError("Error sending email"),
    });

  // Preview Email
  const previewEmail = async (
    caseId: string,
    payload: Email,
    screenResult?: ScreenResultViewModel,
    booking?: BackendBookingViewModel
  ) => {
    return await $api<InternalApi[`/api/cm/case/:id/emails/preview`]["post"]>(
      `/api/cm/case/${caseId}/emails/preview`,
      {
        method: "POST",
        body: getPayload({
          recipients: payload.recipients,
          emailTemplate: payload.emailTemplate,
          message: payload.message,
          screenResult: screenResult,
          booking: booking,
        }),
      }
    );
  };

  const usePreviewEmailMutation = () =>
    createMutation(
      ({
        caseId,
        payload,
        screenResult,
        booking,
      }: {
        caseId: string;
        payload: Email;
        screenResult?: ScreenResultViewModel;
        booking?: BackendBookingViewModel;
      }) => previewEmail(caseId, payload, screenResult, booking),
      {
        onError: () => notifyError("Error previewing email"),
      }
    );

  return {
    useSendTestEmailMutation,
    useSendCaseEmailMutation,
    usePreviewEmailMutation,
  };
};
