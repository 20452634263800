import { unref, type MaybeRef } from "vue";
import { navigateTo, useNuxtApp } from "#imports";
import { useQueryClient } from "@tanstack/vue-query";
import useNotify from "~/composables/useNotify";
import { createQuery, createMutation } from "~/utils/queryUtils";
import { QUERY_KEYS } from "~/utils/queryKeys";
import { getPayload } from "./EmailService";

import type { EmailTemplate } from "../models/Email/EmailTemplate.model";
import type { RequestObject } from "../models/utils/Api.model";
import type { InternalApi } from "nitropack";
import type { MyQueryOptions } from "~/utils/queryUtils";
import type { Email } from "../models/Email/Email.model";
import type { ScreenResultViewModel } from "../models/Case/Screen.model";
import type { BackendBookingViewModel } from "../models/Case/Booking.viewmodel";

const getMailList = (payload: EmailTemplate) => {
  return {
    ...payload,
    mailList: payload.mailList.map((email) => {
      if (email) return { address: email };
    }),
  };
};

export const useEmailTemplateService = () => {
  const { $api } = useNuxtApp();
  const client = useQueryClient();
  const { notifyError, notifySuccess } = useNotify();

  const getEmailTemplate = async (id: string, signal?: AbortSignal) => {
    if (id === "new") return;
    return await $api<InternalApi["/api/cm/email-templates/:id"]["get"]>(
      `/api/cm/email-templates/${id}`,
      { signal }
    );
  };

  const useGetEmailTemplateQuery = async (
    id: string,
    options?: MyQueryOptions
  ) =>
    createQuery(
      [QUERY_KEYS.EmailTemplates.get, id],
      ({ signal }) => getEmailTemplate(id, signal),
      options
    );

  const listEmailTemplates = async (req: RequestObject, signal?: AbortSignal) =>
    await $api<InternalApi["/api/cm/email-templates"]["get"]>(
      "/api/cm/email-templates/",
      {
        query: {
          page: req.page,
          size: req.pageSize,
          name: req.search,
        },
        signal,
      }
    );

  const useListEmailTemplatesQuery = async (
    req: MaybeRef<RequestObject> = {
      page: 1,
      pageSize: 20,
    },
    opt?: MyQueryOptions
  ) =>
    createQuery(
      [QUERY_KEYS.EmailTemplates.list],
      ({ signal }) => listEmailTemplates(unref(req), signal),
      opt
    );

  const createEmailTemplate = async (payload: EmailTemplate) =>
    await $api<InternalApi["/api/cm/email-templates"]["post"]>(
      "/api/cm/email-templates",
      {
        method: "POST",
        body: getMailList(payload),
      }
    );

  const useCreateEmailTemplateMutation = () =>
    createMutation((template: EmailTemplate) => createEmailTemplate(template), {
      onError: () => notifyError("Error creating email template"),
      onSuccess: () => {
        client.invalidateQueries({
          queryKey: [QUERY_KEYS.EmailTemplates.list],
        });
        notifySuccess("Success", "Email template created");
        navigateTo("/email-templates");
      },
    });

  // Update Email Template
  const updateEmailTemplate = async (id: string, payload: EmailTemplate) =>
    await $api<InternalApi["/api/cm/email-templates/:id"]["put"]>(
      `/api/cm/email-templates/${id}`,
      {
        method: "PUT",
        body: getMailList(payload),
      }
    );

  const useUpdateEmailTemplateMutation = () => {
    type UpdateEmailTemplatePayload = {
      id: string;
      values: EmailTemplate;
    };
    return createMutation(
      ({ id, values }: UpdateEmailTemplatePayload) =>
        updateEmailTemplate(id, values),
      {
        onSuccess: (x, { id }) => {
          client.invalidateQueries({
            queryKey: [QUERY_KEYS.EmailTemplates.get, id],
          });
          client.invalidateQueries({
            queryKey: [QUERY_KEYS.EmailTemplates.list],
          });
          notifySuccess("Success", "Email template updated");
        },
        onError: () => {
          return notifyError("Error updating email template");
        },
      }
    );
  };

  // Delete Email Template
  const deleteEmailTemplate = async (id: string) =>
    await $api(`/api/cm/email-templates/${id}`, {
      method: "DELETE",
    });

  const useDeleteEmailTemplateMutation = () =>
    createMutation((id: string) => deleteEmailTemplate(id), {
      onSuccess: () => {
        client.invalidateQueries({
          queryKey: [QUERY_KEYS.EmailTemplates.list],
        });
        notifySuccess("Success", "Email Template deleted");
      },
      onError: () => notifyError("Error Deleting email template"),
    });

  // Export
  const exportCaseEmail = async (
    caseId: string,
    payload: Email,
    screenResult?: ScreenResultViewModel,
    booking?: BackendBookingViewModel
  ) =>
    await $api<InternalApi["/api/cm/case/:id/emails"]["post"]>(
      `/api/cm/case/${caseId}/emails/download`,
      {
        method: "POST",
        body: getPayload({
          recipients: payload.recipients,
          message: payload.message,
          emailTemplate: payload.emailTemplate,
          screenResult: screenResult,
          booking: booking,
        }),
      }
    );

  const useExportCaseEmailMutation = () =>
    createMutation(
      ({
        caseId,
        payload,
        screenResult,
        booking,
      }: {
        caseId: string;
        payload: Email;
        screenResult?: ScreenResultViewModel;
        booking?: BackendBookingViewModel;
      }) => exportCaseEmail(caseId, payload, screenResult, booking),
      {
        onError: () => notifyError("Error creating email"),
        onSuccess: () => notifySuccess("Success", "Email created"),
      }
    );

  return {
    useListEmailTemplatesQuery,
    useGetEmailTemplateQuery,
    getEmailTemplate,
    createEmailTemplate,
    listEmailTemplates,
    useUpdateEmailTemplateMutation,
    useCreateEmailTemplateMutation,
    useDeleteEmailTemplateMutation,
    useExportCaseEmailMutation,
  };
};
